import React from "react";
import Registry from "@ui-platform/extensibility";

const dashboards = [
  {
    key: "threat_insights_report",
    path: "/threat_insights",
    description:
      "See how your security subscriptions are protecting you. Peer data gives you perspective on your industry’s threat landscape and ━ for subscriptions you’re not using ━ shows you where you might be exposed to threats.",
    subPages: ["/threat_activity"],
    group: "activity",
    name: {
      id: "reports.threat_insights_report_title",
      defaultMessage: "Threat Insights"
    }
  },
  {
    key: "executive_report",
    description:
      "See how your security subscriptions are protecting you. Peer data gives you perspective on your industry’s threat landscape and ━ for subscriptions you’re not using ━ shows you where you might be exposed to threats.",
    path: "/executive",
    group: "activity",
    name: {
      id: "reports.executive_report_title",
      defaultMessage: "Executive Summary"
    }
  },
  {
    key: "app_usage_report",
    description:
      "Know the security challenges associated with the applications traversing your network. Key findings here help you to refine security rules for unsanctioned and risky applications.",
    path: "/app_usage",
    group: "activity",
    name: {
      id: "reports.app_usage_report_title",
      defaultMessage: "Application Usage"
    }
  },
  {
    key: "prisma_access_report",
    path: "/usage",
    description:
      "See how you’re leveraging what’s available to you with Prisma Access, and get a high-level view into the health and performance of your environment.",
    group: "activity",
    name: {
      id: "prisma_access_report_title",
      defaultMessage: "Prisma Access"
    }
  },
  {
    key: "user_activity_report",
    path: "/uar",
    description:
      "Get visibility into an individual users’ browsing patterns: their most frequently visited sites, the sites with which they’re transferring data, and attempts to access high-risk sites.",
    group: "activity",
    name: {
      id: "user_activity_report_title",
      defaultMessage: "User Activity"
    }
  },
  {
    key: "wildfire_report",
    path: "/wildfire",
    description:
      "WildFire protects against net new malware that’s concealed in files, executables, and email links.",
    group: "activity",
    subPages: ["/wildfire/samples"],
    name: {
      id: "reports.wildfire_report_title",
      defaultMessage: "WildFire"
    }
  },
  {
    key: "dns_report",
    path: "/dns",
    description:
      "DNS Security protects your network from advanced threats that use DNS. Without DNS Security in place, malware might have infiltrated your network through malicious DNS requests and remained unnoticed.",
    group: "activity",
    name: {
      id: "reports.dns_report_title",
      defaultMessage: "DNS Security"
    },
    subPages: ["/dns/dns_requests"]
  },
  {
    key: "advanced_url_filtering_report",
    path: "/advanced_url_filtering",
    description: "Examine your network’s URL activity by risk level, category, and region.",
    group: "activity",
    name: {
      id: "reports.advanced_url_filtering_title",
      defaultMessage: "Advanced URL Filtering"
    }
  },
  {
    key: "advanced_threat_prevention_report",
    path: "/advanced_threat_prevention",
    description: "Advanced Threat Prevention",
    group: "activity",
    name: {
      id: "reports.advanced_threat_prevention_title",
      defaultMessage: "Advanced Threat Prevention"
    }
  },
  {
    key: "executive_report_dlp_details_report",
    path: "/dlp_details",
    description: "DLP Dashboard",
    group: "activity",
    name: {
      id: "reports.executive_report_dlp_details_title",
      defaultMessage: "DLP Dashboard"
    }
  },
  {
    key: "network_activity_report",
    path: "/network_activity",
    description:
      "Get a view into the different sources generating traffic in your network, and drill down to investigate any unexpected findings.",
    group: "health",
    name: {
      id: "reports.network_activity_report_title",
      defaultMessage: "Network Usage"
    }
  },
  {
    key: "best_practices_report",
    path: "/bpa",
    description:
      "Best practices include checks for the Center for Internet Security’s Critical Security Controls (CSC).",
    group: "posture",
    name: {
      id: "best_practices_report_title",
      defaultMessage: "Best Practices"
    }
  },
  {
    key: "sase_advanced_detections_report",
    path: "/sase_advanced_detections",
    description: "SASE Advanced Detections",
    group: "activity",
    name: {
      id: "reports.sase_advanced_detections_report_title",
      defaultMessage: "SASE Advanced Detections"
    }
  }
];

dashboards.forEach((d) => {
  Registry.contributeExtension("vnr.ia.dashboards", {
    id: d.key,
    config: {
      ...d,
      component: async () => await import("../Dashboard"),
      isAllowed: (state: any) => state.reporting?.reportsById[d.key]?.allowed
      // isLoading: (state) =>
      //     state.reporting?.status !== "success" && state.reporting?.status !== "failure",
    }
  });
});
// Supporting Log Forwarding in SCM UI
Registry.contributeExtension("vnr.components", {
  id: "vnr_log_forwarding",
  config: {
    id: "vnr_log_forwarding",
    title: "vnr_log_forwarding",
    widgetSettings: [],
    settingsValues: {},
    team: "vnr",
    groupConfig: {
    },
    component: React.lazy(async () => await import("../LogForwarding")),
    enabled: true
  }
});
