import getManagedAppName from "./get-managed-app-name";

/**
 * More details can be found in https://docs.google.com/document/d/1kOGhweGJsCVc2QTiihsn9EYmGfJYa0ho4nfwMSIfmFQ/edit
 * @param getAuthState function
 * @returns pa_enabled flag
 */
export const isPaEnabled = (getAuthState: () => any) => {
  const instances = getAuthState()?.instances;
  return (
    Boolean(instances?.has("prisma_access_edition")) && Boolean(instances?.has("prisma_access"))
  );
};

function getSparkyMainState(
  hasTsgSupport: boolean,
  getAuthState: () => any,
  getMainState: () => any,
  StartupManager?: any
) {
  const managedAppName = getManagedAppName(hasTsgSupport, getAuthState, StartupManager);
  const paInstance = getAuthState?.()?.instances?.get(managedAppName);
  const main = hasTsgSupport
    ? {
        tenant: {
          info: {
            region: paInstance?.region
          },
          pa_enabled: isPaEnabled(getAuthState),
          is_early_access_ngfw_tenant: false // if true we disable prisma_access_report (/dashboards/usage) dashboard. Always false for new scm state
        },
        loggedInUser: {
          roles: getAuthState()?.access?.tsg?.roleNames ?? []
        }
      }
    : getMainState() || {};
  return main;
}

export default getSparkyMainState;
